import { render, staticRenderFns } from "./index.vue?vue&type=template&id=13877386&v-if=routesList.length"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/mer_mer_admin_9dWK/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13877386')) {
      api.createRecord('13877386', component.options)
    } else {
      api.reload('13877386', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=13877386&v-if=routesList.length", function () {
      api.rerender('13877386', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/index.vue"
export default component.exports